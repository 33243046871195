import { Locale } from 'date-fns'
import { enUS, pl, arSA } from 'date-fns/locale'

export function formatLocalizedDate(
  day: Date,
  locale: string,
  options?: Intl.DateTimeFormatOptions
): string {
  const localeString = locale === 'ar' ? 'ar-BH' : locale
  return new Intl.DateTimeFormat(localeString, options).format(day)
}

export function getDateFnsLocale(locale: string): Locale {
  if (locale === 'pl') return pl
  if (locale === 'ar') return arSA
  return enUS
}
