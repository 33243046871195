import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'

export const OptionsWrapper = styled.div`
  display: flex;
  max-width: 1024px;
  margin: 0 auto;
  padding: 8px 8px 8px 36px;
  align-items: center;
  gap: 4px;
  border-radius: 36px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(9, 55, 105, 0.08);
  width: 340px;

  ${mediaUp('md')} {
    max-width: 1024px;
    width: unset;
    gap: 12px;
  }

  ${mediaUp('lg')} {
    gap: 36px;
  }
`

export const MobileSearchBoxWrapper = styled.div`
  box-sizing: border-box;
`

export const MobileOptionsWrapper = styled.div`
  display: grid;
  width: fit-content;
  margin: 0 auto;
`

export const SearchButtonWrapper = styled.button`
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fff;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  border-radius: 40px;
  background: #3586e6;
  margin: 12px auto;
  width: 300px;

  img {
    max-height: 12px;
  }

  ${mediaUp('md')} {
    padding: 15px 36px;
    gap: 12px;
    font-size: 18px;
    border-radius: 28px;
    margin: unset;
    width: unset;
  }
`

export const MobileSearchWrapper = styled.div`
  display: grid;
`

export const LocationInputWrapper = styled.div`
  display: flex;
  width: 300px;
  padding: 8px 18px;
  align-items: center;
  gap: 10px;
  border-radius: 15px 15px 0px 0px;
  border-bottom: 1px solid #dfe7f1;
  background: #fff;
  color: #0b4684;
  font-family: Nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  touch-action: manipulation;
`

export const DateInputWrapper = styled.div`
  display: flex;
  width: fit-content;
`

export const DateInputItem = styled.div`
  display: flex;
  width: 150px;
  padding: 8px 18px;
  align-items: center;
  gap: 10px;
  border-radius: 0px 0px 0px 15px;
  border-top: 1px solid #dfe7f1;
  border-right: 1px solid #dfe7f1;
  background: #fff;
  color: #0b4684;
  font-family: Nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: normal;

  &:last-child {
    border-radius: 0px 0px 15px 0px;
    border-left: 1px solid #dfe7f1;
  }
`

export const ModalOverlay = styled.div`
  background: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto;
`
export const ModalContent = styled.div`
  padding: 20px;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ModalTitle = styled.h2`
  color: #093769;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #093769;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  width: 60px;
  justify-content: flex-end;
`

export const SearchInput = styled.input`
  display: flex;
  width: 100%;
  padding: 8px 18px;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid #dfe7f1;
  background: #fff;
  color: #0b4684;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  margin: 32px 0;
  &:placeholder {
    color: #0b4684;
  }

  ${mediaUp('md')} {
    width: 100%;
    max-width: 100%;
  }
`

export const RecentSearchesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`

export const RecentSearchesTitle = styled.span`
  color: #6c7075;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
`

export const ClearHistoryButton = styled.button`
  color: #3586e6;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 900;
  background: none;
  border: none;
  cursor: pointer;
`

export const SearchItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  strong {
    font-weight: bold;
    color: #0b4684;
  }
`

export const SearchItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  span {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 8px;
    text-align: left;
    font-weight: 400;
  }
  button {
    margin-left: auto;
  }
`

export const BottomSection = styled.div`
  background: #fff;
  display: flex;
  width: 100vw;
  position: fixed;
  bottom: calc(20px + max(env(safe-area-inset-bottom, 0px), 10px));
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const ContinueButton = styled.button`
  display: flex;
  width: 100%;
  padding: 8px 18px;
  justify-content: center;
  border-radius: 28px;
  background: #3586e6;
  align-items: center;
  gap: 8px;
  color: #fff;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 900;
  margin: 0 auto;
`

export const SearchWithoutDateButton = styled.button`
  color: #3586e6;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0 auto;
  padding: 8px 0;
`
export const DesktopWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`

export const MobileWrapper = styled.div`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfe7f1;
  margin: 8px 0;
`
