import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'
import { InputTextStyles } from '@/styles/typography'
import { colors, transitions } from '@/styles/theme'

export const DatePickerWrapperStyles = styled.div<{ noMargin: boolean }>`
  width: 100%;
  margin-bottom: ${props => (props.noMargin ? '0' : '18px')};
  ${mediaUp('lg')} {
    position: relative;
    margin-bottom: ${props => (props.noMargin ? '0' : '40px')};
  }

  .rdp {
    ${InputTextStyles};
    color: var(--main);
    display: inline-block;
    position: relative;
    flex-direction: row;
    padding-bottom: 0.25em;
    user-select: none;
    max-width: 100%;
  }

  .rdp-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 3px;
  }

  .rdp-months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .rdp-month {
    display: table;
    width: 100%;
    margin: 1.5em 0 0;
    border-spacing: 0.5em;
    border-collapse: separate;
    user-select: none;

    ${mediaUp('md')} {
      border-spacing: 1em;
    }

    @media (max-width: 767px) {
      margin: 0.9em 0 0;
      border-spacing: 0.3em;
    }
  }

  .rdp-caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 0.3em;
      padding: 0 0.3em;
    }
  }

  .rdp-row {
    display: table-row;
    text-align: center;
  }

  .rdp-table {
    --rdp-cell-size: calc(86vw / 7);
  }

  .rdp-tbody {
    display: table-row-group;
  }

  .rdp-week {
    display: table-row;
    width: 100%;
  }

  .rdp-head {
    color: ${colors.battleshipGrey};
  }

  .rdp-cell {
    display: table-cell;
    border-radius: 0.55em;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-weight: 900;
    font-size: 15px;
    transition: background-color ${transitions.base}, color ${transitions.base};
    padding: 0.2em 0;
    ${mediaUp('lg')} {
      padding: 0.625em;
    }

    ${mediaUp('md')} {
      padding: 0.395em 0.575em;
    }
  }

  .rdp--interactionDisabled .rdp-day {
    cursor: default;
  }

  .rdp-day--today {
    font-weight: 900;
  }

  .rdp-dayoutside,
  .rdp-day_disabled {
    color: ${colors.cloudyBlue};
    cursor: default;
  }

  .rdp-day_disabled {
    opacity: 0.4;
  }

  .rdp-day:hover {
    background-color: #3586e6;
    border-radius: 12px;
  }

  .rdp-day_selected {
    color: #0b4684;
    background-color: #f2f6fd;
    width: 110%;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    color: var(--tint);
    background-color: #f2f6fd;
    border-radius: 12px 0 0 12px;
    position: relative;
    z-index: 1;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end)::before {
    content: '';
    background-color: #3586e6;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: -1;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    color: var(--tint);
    background-color: #f2f6fd;
    border-radius: 0 12px 12px 0;
    position: relative;
    z-index: 1;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start)::before {
    content: '';
    background-color: #3586e6;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    z-index: -1;
  }

  .rdp-day_range_start {
    background-color: #3586e6;
    color: var(--tint);
  }
`
