import { useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { format, isValid, parseISO } from 'date-fns'
import { DayPicker, DateRange, SelectRangeEventHandler } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { useRouter } from 'next/router'

import { useMarket } from '@/utils/multi-markets/context'
import { getDateFnsLocale } from '@/utils/dateFormatters'

import {
  DatePickerWrapper,
  DateInputWrapper,
  DateInput
} from './DayPicker.styled'
import { DatePickerWrapperStyles } from './DatePickerWrapperStyles'

interface DatePickerProps {
  value: string
  setSettedStartDate: (value: string) => void
  setSettedEndDate: (value: string) => void
  label: string
  placeholder?: string
  isReservationComponent: boolean
  settedStartDate: string
  settedEndDate: string
}

const DatePicker: React.FC<DatePickerProps> = ({
  value,
  setSettedStartDate,
  setSettedEndDate,
  settedStartDate,
  settedEndDate
}) => {
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    () => {
      if (settedStartDate && settedEndDate) {
        const fromDate = parseISO(settedStartDate)
        const toDate = parseISO(settedEndDate)

        if (isValid(fromDate) && isValid(toDate)) {
          return { from: fromDate, to: toDate }
        }
      }
      return undefined
    }
  )

  const { locale } = useMarket()
  const router = useRouter()
  const { t } = useTranslation('parkings')

  const handleRangeSelect: SelectRangeEventHandler = range => {
    setSelectedRange(range)
    if (
      range &&
      range.from &&
      range.to &&
      isValid(range.from) &&
      isValid(range.to)
    ) {
      const startDate = format(range.from, 'yyyy-MM-dd')
      const endDate = format(range.to, 'yyyy-MM-dd')
      setSettedStartDate(startDate)
      setSettedEndDate(endDate)
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            startDate,
            endDate
          }
        },
        undefined,
        { shallow: true }
      )
    }
  }

  useEffect(() => {
    if (value && !selectedRange) {
      const [from, to] = value.split(' - ')
      const fromDate = new Date(from)
      const toDate = new Date(to)

      if (isValid(fromDate) && isValid(toDate)) {
        setSelectedRange({
          from: fromDate,
          to: toDate
        })
      }
    }
  }, [value, selectedRange])

  return (
    <DatePickerWrapperStyles noMargin={false}>
      <DatePickerWrapper>
        <DateInputWrapper>
          <DateInput
            className="start-date"
            style={{ borderRight: '1px solid #DFE7F1' }}
          >
            {selectedRange?.from && isValid(selectedRange.from)
              ? format(selectedRange.from, 'MMM dd, yyyy')
              : t('searchNearParking.search.startDate')}
          </DateInput>
          <DateInput className="end-date">
            {selectedRange?.to && isValid(selectedRange.to)
              ? format(selectedRange.to, 'MMM dd, yyyy')
              : t('searchNearParking.search.endDate')}
          </DateInput>
        </DateInputWrapper>
        <DayPicker
          mode="range"
          selected={selectedRange}
          onSelect={handleRangeSelect}
          locale={getDateFnsLocale(locale)}
          disabled={{ before: new Date() }}
        />
      </DatePickerWrapper>
    </DatePickerWrapperStyles>
  )
}

export default DatePicker
