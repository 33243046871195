import React, { useState, useRef, useCallback, useEffect } from 'react'

import { useTranslation } from 'next-i18next'
import { v4 as uuidv4 } from 'uuid'

import airportIcon from '@/assets/search-engine/park-and-fly.svg'
import locationIcon from '@/assets/search-engine/location.svg'
import clockIcon from '@/assets/search-engine/clock.svg'
import deleteIcon from '@/assets/search-engine/delete-recent-search.svg'

import {
  DropdownContainer,
  Input,
  DropdownList,
  ListItem,
  NoOptionsMessage,
  RecentSearchesWrapper,
  RecentSearchesTitle,
  ClearHistoryButton,
  SearchItemsWrapper,
  SearchItem,
  Divider
} from './Dropdown.styled'

enum IconType {
  AIRPORT = 'AIRPORT',
  LOCATION = 'LOCATION',
  RECENT_SEARCH = 'RECENT_SEARCH'
}

const getIconByType = (type: IconType) => {
  switch (type) {
    case IconType.AIRPORT:
      return airportIcon
    case IconType.LOCATION:
      return locationIcon
    case IconType.RECENT_SEARCH:
      return clockIcon
    default:
      return locationIcon
  }
}

interface SearchResult {
  id: string
  label: string
  value: [number, number]
  isAirport: boolean
  isRecentSearch?: boolean
}

const MAX_RECENT_SEARCHES = 5
const RECENT_SEARCHES_KEY = 'recentSearches'

type Option = {
  id: number
  label: string
}

type DropdownProps = {
  options: SearchResult[]
  fetchMoreOptions?: () => void
  inputValue: string
  setInputValue: (value: string) => void
  noOptionsMessage: string | null
  placeholder?: string
  field?: {
    value: string
    onChange: (value: SearchResult) => void
  }
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  fetchMoreOptions,
  inputValue,
  setInputValue,
  noOptionsMessage,
  placeholder,
  field
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [recentSearches, setRecentSearches] = useState<SearchResult[]>([])
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation('parkings')

  const handleSelect = (option: SearchResult) => {
    setInputValue(option.label)
    field && field.onChange(option)
    setIsOpen(false)
    saveToRecentSearches(option)
  }

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(() => {
    const savedSearches = localStorage.getItem(RECENT_SEARCHES_KEY)
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches))
    }
  }, [])

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollTop + clientHeight >= scrollHeight) {
      fetchMoreOptions && fetchMoreOptions()
    }
  }

  const saveToRecentSearches = (item: SearchResult) => {
    const newItem = {
      ...item,
      id: uuidv4(),
      isRecentSearch: true
    }

    let updatedSearches = [newItem, ...recentSearches]

    updatedSearches = updatedSearches.filter(
      (search, index, self) =>
        index === self.findIndex(s => s.label === search.label)
    )

    if (updatedSearches.length > MAX_RECENT_SEARCHES) {
      updatedSearches = updatedSearches.slice(0, MAX_RECENT_SEARCHES)
    }

    setRecentSearches(updatedSearches)
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(updatedSearches))
  }

  const removeRecentSearch = (id: string, label: string) => {
    const updatedSearches = recentSearches.filter(item => item.id !== id)
    setRecentSearches(updatedSearches)
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(updatedSearches))

    if (inputValue === label) {
      setInputValue('')
    }
  }

  const highlightMatch = (text: string, highlight: string) => {
    if (!highlight) return text

    const regex = new RegExp(`(${highlight})`, 'i') // 'i' flag to handle also capital and non-capital letters
    const parts = text.split(regex)

    return parts.map((part, index) =>
      regex.test(part) ? <strong key={index}>{part}</strong> : part
    )
  }

  return (
    <DropdownContainer ref={dropdownRef}>
      <Input
        type="text"
        value={inputValue}
        onClick={handleToggle}
        onChange={e => {
          setInputValue(e.target.value)
          if (!isOpen) setIsOpen(true)
        }}
        placeholder={placeholder}
        data-test-id="searchbox-input"
      />
      {isOpen && options?.length ? (
        <DropdownList
          onScroll={handleScroll}
          data-testid="searchbox-suggestions-list"
        >
          {recentSearches.length > 0 && (
            <>
              <RecentSearchesWrapper>
                <RecentSearchesTitle>
                  {t('searchNearParking.search.recentSearches')}
                </RecentSearchesTitle>
                <ClearHistoryButton
                  onClick={() => {
                    setRecentSearches([])
                    localStorage.removeItem(RECENT_SEARCHES_KEY)
                  }}
                >
                  {t('searchNearParking.search.clearHistory')}
                </ClearHistoryButton>
              </RecentSearchesWrapper>
              <SearchItemsWrapper>
                {recentSearches.map((item: SearchResult, index: number) => (
                  <SearchItem key={index} onClick={() => handleSelect(item)}>
                    <img
                      src={getIconByType(IconType.RECENT_SEARCH)}
                      alt="recent search icon"
                      width="20px"
                      height="20px"
                    />
                    <span>{highlightMatch(item.label, inputValue)}</span>
                    <button
                      onClick={e => {
                        e.stopPropagation()
                        removeRecentSearch(item.id, item.label)
                      }}
                    >
                      <img
                        src={deleteIcon}
                        alt="delete icon"
                        width="16px"
                        height="16px"
                      />
                    </button>
                  </SearchItem>
                ))}
              </SearchItemsWrapper>
              <Divider />
            </>
          )}
          {options.length > 0 ? (
            options.map(option => (
              <ListItem
                key={option.id}
                onClick={() => handleSelect(option)}
                data-test-id={`searchbox-item-${option.id}`}
              >
                <img
                  src={getIconByType(
                    option.isAirport ? IconType.AIRPORT : IconType.LOCATION
                  )}
                  alt="location icon"
                  width="20px"
                  height="20px"
                />
                <span>{highlightMatch(option.label, inputValue)}</span>
              </ListItem>
            ))
          ) : (
            <NoOptionsMessage>{noOptionsMessage}</NoOptionsMessage>
          )}
        </DropdownList>
      ) : (
        <></>
      )}
    </DropdownContainer>
  )
}

export default Dropdown
