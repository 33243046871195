export const handleScroll = (setIsFixed: (val: boolean) => void) => {
  const scrollY = window.scrollY
  const fixed = scrollY > 80
  setIsFixed(fixed)
}

export const chooseCurrencyByRegion = (region: string) => {
  switch (region) {
    case 'pl':
      return 'PLN'
    case 'ae':
      return 'AED'
    case 'bh':
      return 'BHD'
    default:
      return ''
  }
}
