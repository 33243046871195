import React, { useEffect, useCallback, useState } from 'react'
import debounce from 'lodash.debounce'
import { useController } from 'react-hook-form'

import { useTranslation } from 'next-i18next'

import { v4 as uuidv4 } from 'uuid'

import { useRouter } from 'next/router'

import closeIcon from '@/assets/search-engine/close-modal.svg'

import { useSearchBox } from '../useSearchBox'

import DatePicker from './DatePicker'
import {
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalTitle,
  CloseButton,
  Divider,
  BottomSection,
  ContinueButton,
  SearchWithoutDateButton
} from './Options.styled'
import SearchContent from './SearchContent'

interface SearchResult {
  id: string
  label: string
  value: [number, number]
  isAirport: boolean
  isRecentSearch?: boolean
}

interface SearchModalProps {
  settedValue: string
  setSettedValue: (value: string) => void
  onCloseModal: () => void
  market: string
  control: any
  submitButtonRef?: React.RefObject<HTMLButtonElement>
  setSettedStartDate: (value: string) => void
  setSettedEndDate: (value: string) => void
  settedStartDate: string
  settedEndDate: string
}

const MAX_RECENT_SEARCHES = 5
const RECENT_SEARCHES_KEY = 'recentSearches'

const SearchModal: React.FC<SearchModalProps> = ({
  settedValue,
  setSettedValue,
  onCloseModal,
  market,
  control,
  submitButtonRef,
  setSettedStartDate,
  setSettedEndDate,
  settedStartDate,
  settedEndDate
}) => {
  const { t } = useTranslation('parkings')
  const { setInputValue: setSearchBoxValue, options } = useSearchBox({
    market,
    t
  })

  const [isOpen, setIsOpen] = useState(false)
  const [recentSearches, setRecentSearches] = useState<SearchResult[]>([])
  const [hasSelected, setHasSelected] = useState(false)
  const [isDateSelection, setIsDateSelection] = useState(false)

  const { field } = useController({
    name: 'location',
    control: control
  })
  const router = useRouter()
  useEffect(() => {
    const savedSearches = localStorage.getItem(RECENT_SEARCHES_KEY)
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches))
    }
  }, [])

  const saveToRecentSearches = (item: SearchResult) => {
    const newItem = {
      ...item,
      id: uuidv4(),
      isRecentSearch: true
    }

    let updatedSearches = [newItem, ...recentSearches]

    updatedSearches = updatedSearches.filter(
      (search, index, self) =>
        index === self.findIndex(s => s.label === search.label)
    )

    if (updatedSearches.length > MAX_RECENT_SEARCHES) {
      updatedSearches = updatedSearches.slice(0, MAX_RECENT_SEARCHES)
    }

    setRecentSearches(updatedSearches)
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(updatedSearches))
  }

  const removeRecentSearch = (id: string) => {
    const updatedSearches = recentSearches.filter(item => item.id !== id)
    setRecentSearches(updatedSearches)
    localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(updatedSearches))
  }

  const debouncedLoadOptions = useCallback(
    debounce((value: string) => {
      if (!hasSelected) {
        setSearchBoxValue(value)
        setIsOpen(value !== '')
      }
    }, 300),
    [setSearchBoxValue, hasSelected]
  )

  useEffect(() => {
    if (settedValue.length > 0) {
      debouncedLoadOptions(settedValue)
    } else {
      setIsOpen(false)
    }
    return () => {
      debouncedLoadOptions.cancel()
    }
  }, [settedValue, debouncedLoadOptions])

  const handleSelect = (option: SearchResult) => {
    setSearchBoxValue(option.label)
    setSettedValue(option.label)
    field.onChange(option)
    saveToRecentSearches(option)
    setHasSelected(true)
    setIsOpen(false)
    setIsDateSelection(true)
    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          locationLabel: option.label,
          lat: option.value[1],
          lon: option.value[0]
        }
      },
      undefined,
      { shallow: true }
    )
  }

  const filteredRecentSearches = recentSearches.filter(search =>
    search.label.toLowerCase().includes(settedValue.toLowerCase())
  )

  const handleSubmitClick = () => {
    if (submitButtonRef?.current) {
      submitButtonRef.current.click()
    }
    onCloseModal()
  }

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            {isDateSelection
              ? t('searchNearParking.search.selectTime')
              : t('searchNearParking.search.enterLocation')}
          </ModalTitle>
          <CloseButton
            onClick={() => {
              onCloseModal()
              setHasSelected(false)
              setIsDateSelection(false)
            }}
          >
            <img src={closeIcon} alt="closeIcon" />
          </CloseButton>
        </ModalHeader>
        {!isDateSelection ? (
          <SearchContent
            settedValue={settedValue}
            setSettedValue={setSettedValue}
            isOpen={isOpen}
            filteredRecentSearches={filteredRecentSearches}
            recentSearches={recentSearches}
            options={options}
            handleSelect={handleSelect}
            removeRecentSearch={removeRecentSearch}
            clearHistory={() => {
              setRecentSearches([])
              localStorage.removeItem(RECENT_SEARCHES_KEY)
            }}
          />
        ) : (
          <DatePicker
            value={settedValue}
            setSettedStartDate={setSettedStartDate}
            setSettedEndDate={setSettedEndDate}
            label={t('searchNearParking.search.selectDates')}
            placeholder={t('searchNearParking.search.selectYourDates')}
            isReservationComponent={true}
            settedStartDate={settedStartDate}
            settedEndDate={settedEndDate}
          />
        )}
      </ModalContent>
      <Divider />
      <BottomSection>
        <ContinueButton
          onClick={() => {
            if (isDateSelection) {
              handleSubmitClick()
            } else {
              setIsDateSelection(true)
            }
          }}
        >
          {isDateSelection
            ? t('searchNearParking.search.button')
            : t('searchNearParking.search.continue')}
        </ContinueButton>
        {isDateSelection && (
          <SearchWithoutDateButton
            type={'submit'}
            data-test-id="search-button-wrapper-mobile"
            onClick={handleSubmitClick}
          >
            {t('searchNearParking.search.searchWithoutDates')}
          </SearchWithoutDateButton>
        )}
      </BottomSection>
    </ModalOverlay>
  )
}

export default SearchModal
