import styled from '@emotion/styled'

export const StyledButton = styled.button<{ buttonType: 'close' | 'discover' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 16px;
  border-radius: 30px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;

  ${({ buttonType }) =>
    buttonType === 'close'
      ? `
        background: #F1F1F1;
        color: #4D4D4D;
      `
      : `
        background: #3B8DED;
        color: white;
      `}

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    padding: 10px 14px;
    font-size: 16px;
    width: 100%;
  }
`
