import styled from '@emotion/styled'

export const DropdownContainer = styled.div`
  position: relative;
  width: 214px;

  @media (min-width: 769px) {
    width: 320px;
  }

  @media (min-width: 1024px) {
    width: unset;
  }
`

export const Input = styled.input`
  width: 100%;
  font-family: Nunito;
  font-size: 12px;
  color: #0b4684;
  font-weight: 600;
  box-sizing: border-box;
  @media (min-width: 769px) {
    width: 320px;
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    width: 380px;
    font-size: 18px;
    padding: 8px;
  }

  &::placeholder {
    color: #0b4684;
    font-weight: 600;
    font-size: 12px;
    @media (min-width: 769px) {
      font-size: 16px;
    }
    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }
`

export const DropdownList = styled.div`
  all: unset;
  position: absolute;
  top: 52px;
  left: -32px;
  max-height: 150px;
  overflow-y: auto;
  background: white;
  z-index: 1000;
  box-shadow: 0px 4px 24px 0px rgba(9, 55, 105, 0.08);
  border-radius: 16px;
  width: 330px;
  @media (min-width: 769px) {
    width: 460px;
    border-radius: 24px;
    max-height: 200px;
    top: 54px;
    left: -32px;
  }
`

export const ListItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  background-color: white;
  border-radius: 16px;
  font-size: 18px;
  color: #0b4684;
  font-family: Nunito;
  font-weight: 700;
  line-height: 18px;
  text-align: left;

  strong {
    color: #0b4684;
    font-weight: 400;
  }

  span {
    padding: 0 0 0 12px;
  }

  &:hover {
    background: #f2f6fd;
    border-radius: 0px;
  }

  @media (min-width: 769px) {
    border-radius: 24px;
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    padding: 12px 36px;
    border-radius: 24px;
    font-size: 18px;
  }
`

export const NoOptionsMessage = styled.div`
  padding: 8px;
  color: #999;
  text-align: center;
  font-size: 12px;
  font-family: Nunito;

  @media (min-width: 769px) {
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    font-size: 18px;
  }
`

export const RecentSearchesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 36px;
`

export const RecentSearchesTitle = styled.span`
  color: #6c7075;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
`

export const ClearHistoryButton = styled.button`
  color: #3586e6;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
`

export const SearchItemsWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: 12px;

  white-space: nowrap;

  strong {
    font-weight: bold;
    color: #0b4684;
  }
`

export const SearchItem = styled.div`
  display: flex;
  padding: 12px 36px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  gap: 12px;

  &:hover {
    background: #f2f6fd;
    border-radius: 0px;
  }

  span {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  strong {
    font-weight: 400;
  }

  button {
    margin-left: auto;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfe7f1;
  margin: 8px 0;
`
