import styled from '@emotion/styled'

export const PopupWrapperOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media (max-width: 768px) {
    align-items: flex-start;
    padding-top: 20px;
  }
`

export const PopupWrapperBottom = styled.div`
  position: fixed;
  bottom: 0;
  max-width: 1440px;
  background: #02264c;
  border-radius: 16px;
  box-shadow: 0px 4px 24px rgba(9, 55, 105, 0.16);
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  @media (min-width: 768px) {
    padding-right: 48px;
  }
`

export const PopupWrapperCorner = styled.div`
  position: fixed;
  right: 16px;
  bottom: 16px;
  width: 360px;
  background: #f8f8f8;
  border-radius: 16px;
  box-shadow: 0px 4px 24px rgba(9, 55, 105, 0.16);
  padding: 16px;
`

export const PopupContentOverlay = styled.div`
  background: #ffffff;
  border-radius: 32px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 840px;
  position: relative;

  @media (max-width: 768px) {
    width: 90%;
    padding: 16px;
    border-radius: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const PopupContentBottom = styled.div`
  display: grid;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const PopupImageOverlay = styled.img`
  width: 100%;
  height: auto;
  border-radius: 16px;

  @media (max-width: 768px) {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -16px;
    border-radius: 16px 16px 0 0;
    object-fit: cover;
  }
`

export const PopupBottomImageTextCnt = styled.div`
  display: grid;
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
  }
`

export const PopupImageBottom = styled.img`
  height: 150px;
  border-radius: 16px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`

export const PopupBottomText = styled.div`
  display: grid;
  padding: 0 24px;
  white-space: nowrap;
  @media (max-width: 768px) {
    gap: 24px;
    padding: 24px;
    justify-content: center;
    text-align: center;
  }
`

export const PopupImageCorner = styled.img`
  width: 100%;
  height: auto;
  border-radius: 16px;
`

export const PopupTextContainer = styled.div`
  position: absolute;
  top: 30%;
  right: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 768px) {
    position: static;
    margin: 0 auto;
    right: auto;
    left: auto;
  }
`

export const PopupMainText = styled.div`
  color: #fff;
  font-family: Nunito;
  font-size: 24px;
  font-weight: 800;
  span {
    color: #3deabf;
  }

  @media (max-width: 1420px) {
    font-size: 18px;
  }
  @media (max-width: 720px) {
    color: #093769;
  }
`

export const PopupMainBottomText = styled.div`
  color: #fff;
  font-family: Nunito;
  font-size: 24px;
  font-weight: 800;
  span {
    color: #3deabf;
  }

  @media (max-width: 1420px) {
    font-size: 18px;
  }
`

export const PopupSecondaryTextOnImage = styled.div`
  margin-top: 12px;
  color: #fff;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;

  @media (max-width: 1420px) {
    font-size: 12px;
    color: #093769;
  }
`

export const PopupSecondaryText = styled.p`
  color: #fff;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 16px;
  @media (max-width: 1420px) {
    font-size: 14px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 8px;
  }
`

export const CloseIcon = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
`
export const CloseIconPopupBottom = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const ButtonMobileWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 24px 24px 24px;
  }
`
