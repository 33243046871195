import { useEffect, useState } from 'react'

export const usePopup = ({ key }: { key: string }) => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean | undefined>()
  useEffect(() => {
    const isResultPagePopupClosed = Boolean(localStorage.getItem(key))
    setIsPopupOpen(!isResultPagePopupClosed)
  }, [])
  return {
    isPopupOpen,
    setIsPopupOpen
  }
}
