import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'
import { InputTextStyles } from '@/styles/typography'
import { colors, transitions } from '@/styles/theme'

export const DayPickerStyles = styled.div<{ noMargin: boolean }>`
  width: 100%;
  margin-bottom: ${props => (props.noMargin ? '0' : '18px')};
  ${mediaUp('lg')} {
    position: relative;
    margin-bottom: ${props => (props.noMargin ? '0' : '40px')};
  }

  .rdp {
    ${InputTextStyles};
    color: var(--main);
    display: inline-block;
    position: relative;
    flex-direction: row;
    padding-bottom: 0.25em;
    user-select: none;

    ${mediaUp('md')} {
      border-spacing: 0.5em;
    }

    @media (max-width: 767px) {
      border-spacing: 0.3em; /* Zmniejszenie o 40% z 0.5em */
    }
  }

  .rdp-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 25px;

    @media (max-width: 767px) {
      top: 15px; /* Zmniejszenie o 40% z 25px */
    }
  }

  .rdp-months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .rdp-month {
    display: table;
    margin: 1.5em 0 0;
    border-spacing: 0.5em;
    border-collapse: separate;
    user-select: none;

    ${mediaUp('md')} {
      border-spacing: 1em;
    }

    @media (max-width: 767px) {
      margin: 0.9em 0 0; /* Zmniejszenie o 40% z 1.5em */
      border-spacing: 0.3em; /* Zmniejszenie o 40% z 0.5em */
    }
  }

  .rdp-caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 0.3em; /* Zmniejszenie o 40% z 0.5em */
      padding: 0 0.3em; /* Zmniejszenie o 40% z 0.5em */
    }
  }

  .rdp-row {
    display: table-row;
    text-align: center;
  }

  .rdp-tbody {
    display: table-row-group;
  }

  .rdp-week {
    display: table-row;
  }

  .rdp-cell {
    display: table-cell;
    border-radius: 0.55em;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-weight: 900;
    transition: background-color ${transitions.base}, color ${transitions.base};
    padding: 0.325em;
    ${mediaUp('lg')} {
      padding: 0.625em;
    }

    ${mediaUp('md')} {
      padding: 0.395em 0.575em;
    }
  }

  .rdp--interactionDisabled .rdp-day {
    cursor: default;
  }

  .rdp-day--today {
    font-weight: 900;
  }

  .rdp-dayoutside,
  .rdp-day_disabled {
    color: ${colors.cloudyBlue};
    cursor: default;
  }

  .rdp-day_disabled {
    opacity: 0.4;
  }

  .rdp-day:hover {
    background-color: var(--main);
    border-radius: 12px;
  }

  .rdp-day_selected {
    color: var(--tint);
    background-color: var(--main);
    border-radius: 12px;
  }
`
