import styled from '@emotion/styled'

export const DayPickerStyles = styled.div<{ noMargin: boolean }>`
  width: 100%;
  margin-bottom: ${props => (props.noMargin ? '0' : '18px')};

  .rdp {
    display: inline-block;
    position: relative;
    flex-direction: row;
    padding-bottom: 0.25em;
    user-select: none;
  }

  .rdp-nav {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 25px;
  }

  .rdp-months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .rdp-month {
    display: table;
    margin: 1.5em 0 0;
    border-spacing: 0.5em;
    border-collapse: separate;
    user-select: none;
  }

  .rdp-caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: center;
  }

  .rdp-row {
    display: table-row;
    text-align: center;
  }

  .rdp-tbody {
    display: table-row-group;
  }

  .rdp-week {
    display: table-row;
  }

  .rdp-cell {
    display: table-cell;
    border-radius: 0.55em;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
  }

  .rdp--interactionDisabled .rdp-day {
    cursor: default;
  }

  .rdp-day--today {
    font-weight: 900;
  }

  .rdp-dayoutside,
  .rdp-day_disabled {
    color: #c0c0c0;
    cursor: default;
  }

  .rdp-day_disabled {
    opacity: 0.4;
  }

  .rdp-day:hover {
    background-color: var(--main);
    border-radius: 12px;
  }

  .rdp-day_selected {
    color: var(--tint);
    background-color: var(--main);
    border-radius: 12px;
  }
`

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const DateInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #dfe7f1;
  border-radius: 16px;
  width: 100%;
  margin: 12px 12px;
`

export const DateInput = styled.div`
  color: #0b4684;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 18px;
`
