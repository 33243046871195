import React from 'react'

import { StyledButton } from './Button.styled'

interface ButtonProps {
  label: string
  onClick: () => void
  buttonType: 'close' | 'discover'
  style?: any
  dataTestId?: string
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  buttonType,
  style,
  dataTestId
}) => {
  return (
    <StyledButton
      onClick={onClick}
      buttonType={buttonType}
      style={style}
      data-test-id={dataTestId}
    >
      {label}
    </StyledButton>
  )
}

export default Button
