import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useRouter } from 'next/router'

import { syncFormWithQuery } from '../queryUtils'
import { FormValues } from '../SearchNearParking.types'
import { ServicesValuesTypes } from '../components/Filters/Services/Services.types'

export const useFormSync = (
  form: UseFormReturn<FormValues>,
  setLatLon: (val: [number, number] | undefined) => void,
  setFilterOptions: (val: any[]) => void,
  setQueryParams: (val: any) => void,
  market: string,
  locale: string,
  setSelectedServices: (services: ServicesValuesTypes[]) => void,
  setRadius: (radius: number | undefined) => void
) => {
  const router = useRouter()

  useEffect(() => {
    if (router.isReady) {
      syncFormWithQuery(
        router.query,
        form,
        setLatLon,
        setFilterOptions,
        setQueryParams,
        market,
        locale,
        setSelectedServices
      )
    }
  }, [router.isReady, router.query])
}
