import { useRouter } from 'next/router'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useState, useEffect } from 'react'

import { useMarket } from '@/utils/multi-markets/context'
import { Parking } from '@/service'

import { Market } from '@/utils/multi-markets/types'

import { Locale } from '@/utils/i18n/types'

import { FormValues, LocationType } from '../SearchNearParking.types'
import { FilterOptionTypes } from '../components/Filters/Filters.types'
import { updateQueryParams } from '../queryUtils'

import { useLocationFilter } from './useLocationFilter'
import { useServiceFilter } from './useServiceFilter'
import { useScrollEffect } from './useScrollEffect'
import { useOverlayToggle } from './useOverlayToggle'
import { useFetchAndFilterParkings } from './useFetchAndFilterParkings'
import { useFormSync } from './useFormSync'
import { usePriceFilter } from './usePriceFilter'

export const useSearchNearParking = ({
  setParkingsCount
}: {
  setParkingsCount?: (val: number) => void
}) => {
  const { market, locale } = useMarket()
  const router = useRouter()

  const form: UseFormReturn<FormValues> = useForm({
    defaultValues: {
      location: '' as LocationType,
      startDate: '',
      endDate: ''
    }
  })
  const [filterOptions, setFilterOptions] = useState<FilterOptionTypes[]>([])
  const { isFixed } = useScrollEffect()
  const { latLon, setLatLon, setLocation } = useLocationFilter(form)
  const { selectedServices, setSelectedServices, handleAddRemoveService } =
    useServiceFilter()
  const { isOverlayVisible, handleOverlayToggle } = useOverlayToggle()
  const { priceRange, handlePriceChangeComplete } = usePriceFilter()
  const [radius, setRadius] = useState<number | undefined>(
    router.query.radius ? parseInt(router.query.radius as string) : undefined
  )
  const [currency, setCurrency] = useState('')

  const [queryParams, setQueryParams] = useState<{
    countryCode: Market
    visible: boolean
    size: number
    sorting: string
    radius: number | undefined
    lang: Locale
  }>({
    countryCode: market,
    visible: true,
    size: 30,
    sorting: 'distance;asc',
    radius: radius,
    lang: locale
  })

  useFormSync(
    form,
    setLatLon,
    setFilterOptions,
    params => {
      setQueryParams({ ...params, radius })
    },
    market,
    locale,
    setSelectedServices,
    setRadius
  )

  useEffect(() => {
    if (!router?.query?.lat || !router?.query?.lon) return
    const newQueryParams = updateQueryParams(
      form.getValues('location'),
      form.getValues('startDate'),
      form.getValues('endDate'),
      market,
      locale,
      radius
    )
    setQueryParams(newQueryParams)

    const newQuery: any = { ...router.query }
    if (radius) {
      newQuery.radius = radius
    } else {
      delete newQuery.radius
    }

    router.push(
      {
        pathname: router.pathname,
        query: newQuery
      },
      undefined,
      { shallow: true }
    )
  }, [radius])

  const [isMapShow, setIsShowMap] = useState(false)
  const onMapModalClose = () => setIsShowMap(false)
  const onMapModalOptn = () => setIsShowMap(true)

  const { filteredParkings, isLoading } = useFetchAndFilterParkings(
    queryParams,
    selectedServices,
    currency,
    setCurrency,
    setParkingsCount,
    priceRange
  )

  const removeFilterOption = (id: string) => {
    const newFilterOptions = filterOptions.filter(el => el.id !== id)
    setFilterOptions(newFilterOptions)

    const newQuery = { ...router.query }
    if (id === 'location') {
      delete newQuery.lat
      delete newQuery.lon
      delete newQuery.locationLabel
      form.setValue('location', '' as LocationType)
      setLocation('' as LocationType)
    }
    if (id === 'date') {
      delete newQuery.startDate
      delete newQuery.endDate
      form.setValue('startDate', '')
      form.setValue('endDate', '')
    }

    router.push(
      {
        pathname: router.pathname,
        query: newQuery
      },
      undefined,
      { shallow: true }
    )

    setQueryParams(
      updateQueryParams(
        form.getValues('location'),
        form.getValues('startDate'),
        form.getValues('endDate'),
        market,
        locale,
        radius
      )
    )
    if (newFilterOptions.length === 0) handleOverlayToggle()
  }

  const clearAllFilters = () => {
    form.reset({
      location: '' as LocationType,
      startDate: '',
      endDate: ''
    })
    const newQuery: any = {}
    router.push(
      {
        pathname: router.pathname,
        query: newQuery
      },
      undefined,
      { shallow: true }
    )
    setRadius(100000)
    setQueryParams({
      countryCode: market,
      lang: locale,
      visible: true,
      size: 30,
      sorting: 'distance;asc',
      radius: 100000
    })
  }

  const onSubmit = (data: FormValues, isMainPage?: boolean) => {
    const { location, startDate, endDate } = data
    const newQuery: any = { ...router.query }

    if (location && typeof location !== 'string') {
      newQuery.lat = location.value[1]
      newQuery.lon = location.value[0]
      newQuery.locationLabel = location.label
      setLatLon([location.value[1], location.value[0]])
    } else {
      delete newQuery.lat
      delete newQuery.lon
      delete newQuery.locationLabel
      setLatLon(undefined)
    }

    if (startDate) {
      newQuery.startDate = startDate
    } else {
      delete newQuery.startDate
    }

    if (endDate) {
      newQuery.endDate = endDate
    } else {
      delete newQuery.endDate
    }

    if (selectedServices.length > 0) {
      newQuery.service = selectedServices
    } else {
      delete newQuery.service
    }

    if (priceRange.min !== 0 || priceRange.max !== 1000) {
      newQuery.minPrice = priceRange.min
      newQuery.maxPrice = priceRange.max
    } else {
      delete newQuery.minPrice
      delete newQuery.maxPrice
    }

    if (radius !== undefined) {
      newQuery.radius = radius
    } else {
      newQuery.radius = 100000
    }

    setQueryParams(
      updateQueryParams(location, startDate, endDate, market, locale, radius)
    )

    if (isMainPage) {
      router.push({
        pathname: '/parking-search',
        query: newQuery
      })
      return
    }

    router.push(
      {
        pathname: router.pathname,
        query: newQuery
      },
      undefined,
      { shallow: true }
    )
  }

  const handleGoToReservation = (parking: Parking) => {
    const startDate = form.getValues('startDate')
    const endDate = form.getValues('endDate')

    router.push({
      pathname: parking?.reservationAvailable
        ? '/reservation'
        : '/subscription',
      query: {
        ...(parking?.reservationAvailable && {
          startDate: startDate
        }),
        ...(parking?.reservationAvailable && {
          endDate: endDate
        }),
        parkingUuid: parking?.uuid,
        parking: parking?.urlSlug,
        bookType: parking?.reservationAvailable
          ? 'reservation'
          : 'subscription',
        ...(!parking?.reservationAvailable && {
          startDate: startDate
        })
      }
    })
  }

  return {
    onSubmit,
    handleGoToReservation,
    form,
    parkings: filteredParkings,
    isLoading,
    market,
    locale,
    filterOptions,
    removeFilterOption,
    clearAllFilters,
    isFixed,
    handleOverlayToggle,
    isOverlayVisible,
    isMapShow,
    onMapModalClose,
    onMapModalOptn,
    selectedServices,
    handleAddRemoveService,
    priceRange,
    handlePriceChangeComplete,
    radius,
    setRadius,
    currency,
    latLon
  }
}
