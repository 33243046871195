import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useFetchParkings } from '@/service/apiHooks/useFetchParkings'
import { Parking, TagsTypes } from '@/service'

import { Market } from '@/utils/multi-markets/types'
import { Locale } from '@/utils/i18n/types'

import { ServicesValuesTypes } from '../components/Filters/Services/Services.types'

export const useFetchAndFilterParkings = (
  queryParams: {
    countryCode: Market
    visible: boolean
    size: number
    sorting: string
    radius: number | undefined
    lang: Locale
  },
  selectedServices: ServicesValuesTypes[],
  currency: string,
  setCurrency: (currency: string) => void,
  setParkingsCount?: (val: number) => void,
  priceRange?: { min: number; max: number }
) => {
  const { parkings, isLoading } = useFetchParkings({ params: queryParams })
  const [filteredParkings, setFilteredParkings] = useState<Parking[]>([])
  const router = useRouter()

  useEffect(() => {
    if (parkings) {
      let newFilteredParkings = parkings

      if (selectedServices.length > 0) {
        newFilteredParkings = newFilteredParkings.filter(parking =>
          selectedServices.every(service =>
            parking.infoTags?.includes(service as TagsTypes)
          )
        )
      }

      if (priceRange) {
        newFilteredParkings = newFilteredParkings.filter(parking => {
          const pricePerHour = parking.price?.pricePerHour?.amount || 0
          const pricePer24Hours = parking.price?.pricePer24Hours?.amount || 0
          const price = pricePerHour > 0 ? pricePerHour : pricePer24Hours
          return price >= priceRange.min && price <= priceRange.max
        })
      }
      if (!currency) {
        setCurrency(
          newFilteredParkings?.[0]?.price?.pricePerHour?.currency ??
            newFilteredParkings?.[0]?.price?.pricePerHour?.currency ??
            undefined
        )
      }

      setFilteredParkings(newFilteredParkings)
      setParkingsCount?.(newFilteredParkings.length)
    }
  }, [
    parkings,
    selectedServices,
    priceRange,
    setParkingsCount,
    queryParams,
    router.query
  ])

  return { filteredParkings, isLoading }
}
